.alert {
    background-color: #119A1F;
    padding: 12px;
    color: #FFF;

    border-radius: 15px;
    box-sizing: border-box;

    &.--danger {
        background-color: #EC3959;
    }

    a {
        color: #fff;
    }
}