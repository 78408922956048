.qr-modal {

    .qr-container {
        display: flex;
        justify-content: center;
    }
    
    .qr-image {
        width: 250px;
        height: 250px;
    }
}