.empty {
    width: 900px;
    height: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    &.--with-padding {
        height: auto;
        padding: 100px 0;
    }
}