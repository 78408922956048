.auth-layout {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  .brand {
    display: flex;
    align-items: center;
    padding: 30px;
    cursor: pointer;

    h1 {
      font-size: 14px;
      margin-left: 4px;
      margin: 0;
      color: #8338EC;
    }
  }

  .content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    flex: 1;
  }

  .content {
    width: 498px;
    box-shadow: 0 0 63px 5px rgba(#8338ec, 0.1);
    border-radius: 25px;
    padding: 50px;
    margin: 20px 0;
    box-sizing: border-box;

    .auth-tiitle {
      font-size: 36px;
      font-weight: bold;
      margin: 0;

      text-align: center;
    }

    .auth-slug {
      margin-top: 20px;
      margin-bottom: 0;
      text-align: center;
      font-size: 15px;
      line-height: 24px;
    }

    .text-divider {
      display: flex;
      flex-direction: row;
      align-items: center;

      .divider {
        flex: 1;
        height: 1px;
        background-color: rgba(#8338ec, 0.2);
      }

      .text {
        margin: 0 20px;
      }
    }

    .log-in-with-button {
      width: 100%;

      background-color: transparent;
      border: 2px solid rgba(#8338ec, 0.6);
      outline: none;
      border-radius: 6px;

      padding: 15px 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 500;

      cursor: pointer;

      &:hover {
        border: 2px solid rgba(#8338ec, 1);
      }

      img {
        margin-right: 5px;
      }

      &:first-child {
        margin-right: 10px;
      }
    }

    .sign {
      text-align: center;

      &__link {
        font-weight: bold;
        text-decoration: none;
        color: #8338ec;
      }
    }
  }
}
