html, body, #root {
  width: 100%;
  min-height: 100vh;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
  min-width: 1140px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.flex {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.flex-end {
    justify-content: flex-end;
  }
}