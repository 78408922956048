.short-link {

    &__title {
        margin-bottom: 10px;
    }

    &__header {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__link-field {

        display: flex;
        flex-direction: column;
        width: 50%;
        margin-right: 30px;
        &:last-child {
            margin-right: 0;
        }
        
        &__title {
            display: inline-block;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 5px;
        }

        &__value {
            background-color: rgba(#8338EC, .08);
            padding: 14px 30px;
            border-radius: 15px;

            text-wrap: nowrap;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            a {
                text-decoration: none;
                color: rgba(#000, .45);
            }
        }
    }

    &__stat {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__operation {
        display: flex;
        flex-direction: row;
        margin-top: 30px;

        font-weight: 500;
        font-size: 18px;

        &__item {
            cursor: pointer;
            padding-bottom: 12px;

            &:hover {
                text-decoration: underline;
            }
        }

        &__divider {
            display: inline-block;
            margin: 0 30px;
            width: 1px;
            background-color: rgba(#000, .1);
        }
    }
}