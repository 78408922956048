.input {


    &__title {
        color: rgba($color: #333, $alpha: .6);
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 5px;
    }

    &__control {
        background-color: rgba(#8338EC, .08);
        padding: 24px 30px;
        border: none;
        border-radius: 15px;
        outline: none;
        box-sizing: border-box;

        width: 100%;

        color: #333;

        &::placeholder {
            color: rgba(#000000, .45);
        }
        &:disabled {
            background-color: rgba(#ababab, .08);
        }
    }


    &__error {
        display: block;
        color: red;
        font-size: 14px;
        margin-top: 0;
    }
}