.inner-layout {

    display: flex;
    flex-direction: row;
    
    .content {
        flex: 1;
        overflow-y: auto;
        max-height: 100vh;
        box-sizing: border-box;

        padding: 48px 24px;

        .page-title {
            font-size: 36px;
            font-weight: 500;

            margin-top: 0;
            margin-bottom: 20px;
        }

        .second-page-title {
            font-size: 36px;
            font-weight: 500;

            margin-top: 40px;
            margin-bottom: 20px;
        }
    }
}