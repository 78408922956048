
@keyframes loader-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #d6d6d6;
    border-bottom-color: #8338EC;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: loader-rotation 1s linear infinite;

    &.--for-button {
        width: 12px;
        height: 12px;
        border-width: 1px;
        margin-left: 5px;
    }
}

    