.navigation {
    background-color: #8338EC;
    border-radius: 10px;
    padding: 42px 24px;
    padding-right: 0;
    box-sizing: border-box;

    width: 240px;


    height: calc(100vh - 60px);
    overflow-y: auto;

    margin: 30px;
    margin-right: 0;

    color: #fff;

    .title {
        font-weight: 500;
        font-size: 36px;
    }

    & > * {
        display: block;
    }

    .nav-link {
        font-size: 18px;
        color: #fff;
        text-decoration: none;

        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;

        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        padding: 10px 16px;

        &__img {
            margin-right: 10px;
            width: 24px;
            height: 24px;
        }

        &.main {
            border: 1px #fff dashed;
            border-right: none;
        }

        &:hover, &.active {
            background-color: #FFF;
            color: #333;

            .nav-link__img {
                filter: brightness(0);
            }
        }
    }

    &__section-title {

        display: block;

        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
        opacity: .6;
    }
}