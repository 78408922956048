.panel {
    box-shadow: 0 0 63px 5px rgba(#8338EC, .1);
    padding: 30px;
    border-radius: 25px;

    box-sizing: border-box;

    max-width: 900px;

    .panel-title {
        font-size: 24px;
        font-weight: 500;
    }
}