.graphic {
    height: 374px;
    position: relative;

    // background-color: red;

    .hline {
        height: 0;
        border-bottom: 1px dashed rgba(#333, .15);
        position: absolute;

        left: 0;
        right: 0;
    }

    .y-values-item {
        position: absolute;
        color: rgba(#333333, .3);
        font-size: 15px;
    }

    .x-labels-item {
        position: absolute;
        color: rgba(#333333, .3);
        font-size: 15px;

        bottom: 0;
    }

    .bar {
        position: absolute;
        background-color: #8f4eea;
        border-radius: 50px;

        &:hover {
            background-color: #8339EC;
        }
    }
}