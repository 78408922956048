.stat {
    background: rgba(#8338EC, 0.1);
    background-color: #F5F5F5;
    padding: 18px 15px;
    border-radius: 15px;

    margin-top: 15px;
    margin-right: 15px;

    &.green {
        background-color: rgba(#119A1F, .2);
    }

    &__title {
        color: rgba(#333, 0.6);
        font-size: 15px;
        line-height: 24px;
        margin-right: 4px;
        display: inline-block;
    }

    &__value {
        font-size: 15px;
        font-weight: 500;
    }
}