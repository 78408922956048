.button {
    outline: none;
    border: none;

    background-color: #8338EC;
    border-radius: 6px;
    cursor: pointer;

    padding: 15px 30px;

    color: #FFF;
    font-weight: bold;

    &:hover {
        background-color: #7826e9;
    }
    &:disabled {
        background-color: #ababab;
    }

    &.block {
        width: 100%;
    }
}