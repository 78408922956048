.no-scroll {
    overflow: hidden;
}

.modal {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    flex-direction: row;
    justify-content: center;
    align-items: center;

    overflow-y: auto;

    background-color: rgba($color: #000000, $alpha: .31);

    @keyframes background-animation {
        from {
            background-color: rgba($color: #000000, $alpha: 0);
        }

        to {
            background-color: rgba($color: #000000, $alpha: .2);
        }
    }

    animation: background-animation .5s ease-in-out;

    &__window {

        @keyframes open-animation {
            from {
                opacity: 0;
                transform: translateY(100px);
            }
    
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        background-color: #FFFFFF;
        padding: 50px 40px;
        border-radius: 12px;
        width: 480px;
        margin-top: 20px;
        margin-bottom: 20px;

        animation:  open-animation .5s ease-in-out;
    }


    &__title {
        font-size: 36px;
        line-height: 48px;
        font-weight: 500;

        margin: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    &__description {
        text-align: center;
        font-size: 18px;
        line-height: 24px;
    }
    
}